<template>
  <!-- 审查 -->
  <div class="inline-block line">
    <ColorTextBtn :loading="loading" @click="showDialog"> {{ btnTxt }} </ColorTextBtn>
    <AuthExaminDialog
      title="审核"
      ref="form"
      :sup_this="sup_this"
      :data="approveData"
      :dialogVisible.sync="dialogVisible"
      v-if="dialogVisible"
      v-bind="permision"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import AuthExaminDialog from './module/authExaminDialog'
import { commonEheaderMixin } from '@/mixins'
import {
  getApproveDetail as getData //审核详情
} from '@/api/auth/realNameAuthApi'

import { domPermisionList } from './const'
import {
  NO_REAL_NAME, //未实名认证
  REAL_NAME_STATUS
} from '@/utils/constant'

export default {
  mixins: [commonEheaderMixin],
  components: { AuthExaminDialog },
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      approveData: {}
    }
  },

  computed: {
    /**
     * @description: 权限 dom的权限和分配的权限的合集, 根据不同的状态获取不同的权限
     * @param {Object} data
     * @return {Object}
     */
    permision({ data }) {
      const domPermision = domPermisionList[REAL_NAME_STATUS(data)]
      return {
        ...domPermision,
        hasDownBusinessLicenceFilePath: true
      }
    },

    /**
     * @description: btn文本
     * @param {*} data
     * @return {*}
     */
    btnTxt({ data }) {
      const status = REAL_NAME_STATUS(data)
      return status == NO_REAL_NAME ? '审核' : '详情'
    }
  },

  methods: {
    async showDialog() {
      const [err, data] = await this.getData()
      if (err) return
      this.approveData = data
      // console.log('data', data)
      this.dialogVisible = true
    },

    /**
     * @description: 获取审核详情
     * @param {*}
     * @return {Array} [err, data]
     */
    async getData() {
      this.loading = true
      try {
        const { id } = this.data
        const { detail, code } = await getData({
          id
        })
        return [!$SUC({ code }), detail || {}]
      } catch (err) {
        return [true, {}]
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
