var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('avue-crud',{ref:"crud",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"create_timeSearchForm",fn:function(ref){
var form = ref.form;
return [_c('SaleDateTime',{attrs:{"time":form['create_time']},on:{"update:time":function($event){return _vm.$set(form, 'create_time', $event)},"change":_vm.searchChange}})]}},{key:"nickName",fn:function(ref){
var userDTO = ref.row.userDTO;
return [_vm._v(" "+_vm._s(userDTO && userDTO.nickName)+" ")]}},{key:"accountName",fn:function(ref){
var userDTO = ref.row.userDTO;
return [_vm._v(" "+_vm._s(userDTO && userDTO.accountName)+" ")]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('AuthExamin',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:list:manualAuditList:approve']),expression:"['externaladmin:user:list:manualAuditList:approve']"}],attrs:{"sup_this":_vm.sup_this,"data":row}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }