<template>
  <BaseDialog max-height="70vh" top="10vh" :dialogVisible.sync="dialog" width="1000px" :title="title">
    <AuthForm v-bind="$attrs" @approveCommitSuccess="approveCommitSuccess" />
  </BaseDialog>
</template>
<script>
import AuthForm from '@/views/components/authentication/authForm'
import { commonFromMixin } from '@/mixins'

export default {
  components: {
    AuthForm
  },
  mixins: [commonFromMixin],
  methods: {
    approveCommitSuccess() {
      this.dialog = false
      this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
