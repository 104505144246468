import {
  NO_REAL_NAME, //未实名认证
  REAL_NAME_FAIL, //实名认证失败
  REAL_NAME_SUCCSS //实名认证成功
} from '@/utils/constant'

export const domPermisionList = {
  [NO_REAL_NAME]: {
    showDistributor: true,
    showEnterprise: true,
    showOriginBusinessLicenceFilePath: true,
    showValidPass: true
  },
  [REAL_NAME_FAIL]: {
    showDistributor: true,
    showEnterprise: true,
    showFailExamine: true,
    showChangeAuthExamin: true
  },
  [REAL_NAME_SUCCSS]: {
    showDistributor: true,
    showEnterprise: true,
    showPassExamine: true,
    showChangeAuthExamin: true
  }
}
