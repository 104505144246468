<template>
  <!-- 认证 -->
  <avue-crud
    ref="crud"
    :option="tableOption"
    :data="tableData"
    :page="tablePage"
    :tableLoading="tableLoading"
    @size-change="sizeChange"
    @current-change="pageChange"
    @search-change="searchChange"
  >
    <template #create_timeSearchForm="{ form }">
      <SaleDateTime :time.sync="form['create_time']" @change="searchChange"></SaleDateTime>
    </template>
    <template #nickName="{ row: { userDTO } }">
      {{ userDTO && userDTO.nickName }}
    </template>
    <template #accountName="{ row: { userDTO } }">
      {{ userDTO && userDTO.accountName }}
    </template>
    <template #menu="{ row }">
      <AuthExamin :sup_this="sup_this" :data="row" v-p="['externaladmin:user:list:manualAuditList:approve']" />
    </template>
  </avue-crud>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import SaleDateTime from '@/components/saleDateTime'

import AuthExamin from '@/views/components/authentication/module/authExamin'
import { mapGetters } from 'vuex'
import { tableOption } from './const'

import {
  getManualAuditList as getList //人工审核认证列表
} from '@/api/auth/realNameAuthApi'

export default {
  components: {
    SaleDateTime,
    AuthExamin
  },

  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],

  props: {
    url: {
      type: String,
      default: '/externaladmin/authService/user/distributorUserList'
    },

    hasDetail: Boolean,
    hasAuthExamin: Boolean
  },

  data() {
    return {
      tableOption,
      sup_this: this,
      searchForm: { manualApproveStatus: '0' }
    }
  },

  mounted() {
    this.initSearchForm()
  },

  computed: {
    ...mapGetters({
      userId: 'id'
    })
  },

  methods: {
    beforeInit() {
      this.tableData = []
    },

    /**
     * @description: 初始化crud组件内部的 searchForm
     * @param {*}
     * @return {*}
     */
    initSearchForm() {
      this.$refs.crud.searchForm = {
        ...this.$refs.crud.searchForm,
        ...this.searchForm
      }
    }
  }
}
</script>
