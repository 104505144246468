<template>
  <BaseDialog :dialogVisible.sync="dialog" width="1200px" :title="title" :hiddenFooter="true">
    <EForm />
  </BaseDialog>
</template>
<script>
import EForm from './form'
import { commonFromMixin } from '@/mixins'

export default {
  components: {
    EForm
  },
  mixins: [commonFromMixin],
  props: {
    data: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .crud-container {
    height: 600px;
    display: flex;
    flex-direction: column;
  }
  .crud-header {
    .el-form {
      margin-top: 0;
    }
  }
  .el-table {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-table__body-wrapper {
      flex: 1;
      overflow-y: auto;
    }
  }
  .el-pagination {
    float: initial;
    text-align: right;
  }
}
</style>
